import { DEFAULT_HOTSPOT_STYLE } from "consts";

export const QUERY_KEY = {
  TOUR_LIST: 'TourList',
  TOUR_JSON: 'TourJson',
  TOUR: 'Tour',
  TOUR_GROUP: 'TourGroups',
  GENERATE_TOUR_JSON: 'GenerateTourJson',
};

export const ENDPOINT = {
  TOUR: 'Tours',
  SOFT_DELETE: 'Tours/SoftDelete',
  TOUR_GROUPS: 'TourGroups',
  TOURS_JSON: 'Tours/json',
};

export const DATA_TABLE_CONFIG = {
  noHeader: true,
  pagination: true,
};

export const DEFAULT_ZOOM = 10;

export const ACCESS_CODE_REGEX = /^[a-z0-9-]+$/;

export const SCENE_RATIO = [
  {
    value: '2',
    label: '2:1',
  },
  {
    value: '4',
    label: '4:1',
  },
];

export const RATIO_LIMIT = '2';

export const RATIO_NO_LIMIT = '4';

export const SCENE_RATIO_VALUE = [RATIO_LIMIT, RATIO_NO_LIMIT];

export const VIEWING_ANGLE = {
  DEFAULT: 60,
  MIN: 20,
  MAX: 120,
};

const defaultCoordsData = {
  mapLong: null,
  mapLat: null,
  mapAlt: null,
};

export const DEFAULT_TOUR_DATA = {
  name: '',
  tagline: '',
  location: '',
  friendlyName: '',
  menuHighlightColor: '#F73D3C',
  calloutHeaderColor: '#000000',
  calloutSubHeaderColor: '#FFFFFF',
  lightboxBGColor: '#000000',
  lightboxBGColor1: '',
  lightboxBGColor2: '',
  lightboxBGColor3: '',
  lightboxHeaderColor: '#FFFFFF',
  lightboxBodyTextColor: '#FFFFFF',
  facebookPixelId: '',
  googleAnalyticTrackingId: '',
  brandColor2: '',
  logoUrl: '',
  logo: null,
  autorotate: true,
  autorotateSpeed: 1,
  defaultViewingAngle: 60,
  mapCoordinates: [],
  ...defaultCoordsData,
  mapLightIntensity: 0.5,
  mapLightColor: 'white',
  mapBoxStyle: null,
  menuStyle: 'default',
  hotspotStyle: DEFAULT_HOTSPOT_STYLE,
  lightboxBlur: true,
  sceneRatio: '2',
  accessCode: '',
  enableGyroscope: true,
  active: true,
  allowedIp: '',
  passcode: '',
};

export const TOUR_MAP_COORDINATES = [
  'mapCoordinates',
  'mapLong',
  'mapLat',
  'mapAlt',
];

export const LIGHT_BOX = [
  {
    title: 'branding_color',
    name: 'menuHighlightColor',
  },
  // {
  //   title: 'Callout Header Color',
  //   name: 'calloutHeaderColor',
  // },
  // {
  //   title: 'Callout Distance Color',
  //   name: 'calloutSubHeaderColor',
  // },
  // {
  //   title: 'Lightbox BG Color',
  //   name: 'lightboxBGColor',
  // },
  // {
  //   title: 'Lightbox Header Color',
  //   name: 'lightboxHeaderColor',
  // },
  // {
  //   title: 'Lightbox Body Text Color',
  //   name: 'lightboxBodyTextColor',
  // },
];
